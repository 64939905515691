import Offcanvas from 'bootstrap/js/dist/offcanvas';
import Dropdown from 'bootstrap/js/dist/dropdown';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(ScrollTrigger,ScrollSmoother, SplitText);
ScrollTrigger.clearScrollMemory('manual')
window.history.scrollRestoration = 'manual'

let smoothScrollObj

// function initializeDropdown() {
//     const dropdownElementList = document.querySelectorAll('.dropdown-toggle')
//     const dropdownList = [...dropdownElementList].map(dropdownToggleEl => new Dropdown(dropdownToggleEl))
// }

function smoothScroll() {
    if (ScrollTrigger.isTouch != 1) {
        smoothScrollObj = ScrollSmoother.create({
            smooth: 0.8,
            effects: true,
        });
    }
}

function checkNavbarScrolled() {
    const customNavbar = document.querySelector('.custom-navbar');

    if(customNavbar) {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 50) {
                customNavbar.classList.add('scrolled')
            } else {
                customNavbar.classList.remove('scrolled')
            }
        })
    }
}

function showNavbarDropdown() {
    const navDropdownBtn = document.querySelectorAll('.custom-navbar-dropdown-link')
    const menuDropdownOverlay = document.querySelector('.custom-navbar-dropdown__overlay')
    // const dropdownItem = document.querySelector('.custom-navbar-dropdown__inner')

    if(menuDropdownOverlay) {
        if (window.innerWidth >= 768) {
        
            navDropdownBtn.forEach(mb => {
                mb.addEventListener('mouseenter', (e) => {
                    e.preventDefault()
    
                    /**
                     * Remove the 'show' class from all elements with the class 'menu-dropdown-item'
                     * This is done to hide any previously shown dropdowns before showing the new one
                     */
                    navDropdownBtn.forEach(nd => {
                        nd.classList.remove('active')
                    });
    
                    mb.classList.add('active')
                    menuDropdownOverlay.classList.add('show')
                })
            });
        
            menuDropdownOverlay.addEventListener('mouseenter', () => {
    
                navDropdownBtn.forEach(mb => {
                    mb.classList.remove('active')
                });
                
                menuDropdownOverlay.classList.remove('show')
            })
        } else {
            navDropdownBtn.forEach(mb => {
                mb.addEventListener('click', (e) => {
                    e.preventDefault()
    
                    const dropdownTarget = e.target.dataset.dropdown
    
                    navDropdownBtn.forEach(nd => {
                        // Exclude the selected dropdown from the removal operation
                        if (nd.dataset.dropdown !== dropdownTarget) {
                            nd.classList.remove('active')
                        }
                    });
    
                    mb.classList.toggle('active')
                })
            });
        }
    }
}

function hidePageTransition() {
    gsap.to('.page-transition', {
        duration: 0.3,
        autoAlpha: 0,
    })
}

function charUpAnimation() {
    const charElement = document.querySelectorAll('.char-up-animation')

    charElement.forEach(te => {
        const charTextSplit = new SplitText(te, {type: "chars,lines", linesClass: 'overflow-hidden'})

        gsap.from(charTextSplit.chars, {
            duration: 0.5, 
            y: '100%',
            stagger: 0.02,
            scrollTrigger: {
                trigger: te,
                start: 'top 80%',
            }
        })
    });
}

function fadeUpAnimation() {
    const fadeElement = document.querySelectorAll('.fade-up')

    fadeElement.forEach(fe => {
        gsap.to(fe, {
            duration: 0.5, 
            y: 0,
            autoAlpha: 1,
            stagger: 0.02,
            scrollTrigger: {
                trigger: fe,
                start: 'top-=50px 80%',
                // markers: true,
            }
        })
    });
}

function showNotice() {
    const notice = window.localStorage.getItem('hide-notice-cookie')

    if (!notice) {
        gsap.to('.notice', {
            autoAlpha: 1,
        })
    }
}

function hideNotice() {
    const button = document.querySelector('.notice__button')
    
    button.addEventListener('click', () => {
        window.localStorage.setItem('hide-notice-cookie', true)

        gsap.to('.notice', {
            autoAlpha: 0,
        })
    })
}

smoothScroll()

document.addEventListener('DOMContentLoaded', () => {
    checkNavbarScrolled()
    showNavbarDropdown()
    charUpAnimation()
    fadeUpAnimation()
    showNotice()
    hideNotice()
})

window.addEventListener('load', () => {
    hidePageTransition()
})

export { gsap, ScrollTrigger, ScrollSmoother, SplitText, smoothScrollObj }